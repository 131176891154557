<template>
  <JenisVariableKpiForm mode="Ubah" module="Jenis Variable KPI"> </JenisVariableKpiForm>
</template>

<script>
import JenisVariableKpiForm from './form';

const JenisVariableKpiUpdate = {
  name: 'JenisVariableKpiUpdate',
  components: { JenisVariableKpiForm },
};

export default JenisVariableKpiUpdate;
</script>
